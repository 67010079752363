dmx.Attribute('animate-enter', 'mounted', function(node, attr) {
    node.addEventListener('animationend', function() {
        dmx.animate.removeAllEffects(node);
    });
    dmx.animate(node, attr.value, attr.modifiers.duration, (attr.modifiers.random ? Math.random() * 10 : this.data.$index) * attr.modifiers.delay);
});

dmx.Attribute('animate-leave', 'mounted', function(node, attr) {
    var self = this;

    node.addEventListener('remove', function(event) {
        event.preventDefault();
        var pos = {
            left: node.offsetLeft,
            top: node.offsetTop,
            width: node.offsetWidth,
            height: node.offsetHeight
        };
        requestAnimationFrame(function() {
            node.style.setProperty('position', 'absolute');
            node.style.setProperty('left', pos.left + 'px');
            node.style.setProperty('top', pos.top + 'px');
            node.style.setProperty('width', pos.width + 'px');
            node.style.setProperty('height', pos.height + 'px');
            node.offsetParent.appendChild(node);
            node.addEventListener('animationend', function() {
                dmx.dom.remove(node);
            });
            dmx.animate(node, attr.value, attr.modifiers.duration, (attr.modifiers.random ? Math.random() * 10 : self.data.$index) * attr.modifiers.delay);
        });
    });
});

dmx.Attribute('animate-move', 'mounted', function(node, attr) {
    if (this.type !== 'repeat-item') {
        console.warn('Cannot use animate-move on a component that is not a repeat item');
        return;
    }

    if (!this.parent.props.key) {
        console.warn('Cannot use animate-move on non keyed repeat');
        return;
    }

    var self = this;
    var repeater = this.parent;
    var easing = ['linear', 'ease-in', 'ease-out', 'ease-in-out'].filter(function(easing) { return !!attr.modifiers[easing]; });
    var onUpdate = function(event) {
        node.pos = node.getBoundingClientRect();
    };
    var onUpdated = function(event) {
        if (node.pos) {
            node.style.removeProperty('transform');
            node.style.removeProperty('transition');
            requestAnimationFrame(function() {
                var pos = node.getBoundingClientRect();
                node.style.setProperty('transform', 'translate(' + (node.pos.left - pos.left) + 'px,' + (node.pos.top - pos.top) + 'px)');
                requestAnimationFrame(function() {
                    node.style.setProperty('transition-property', 'all');
                    if (attr.modifiers.delay) {
                        node.style.setProperty('transition-delay', ((attr.modifiers.random ? Math.random() * 10 : self.data.$index) * attr.modifiers.delay) + 'ms');
                    } else {
                        node.style.removeProperty('transition-delay');
                    }
                    node.style.setProperty('transition-duration', (attr.modifiers.duration || 800) + 'ms');
                    node.style.setProperty('transition-timing-function', easing[0] || 'ease');
                    node.style.removeProperty('transform');
                });
            });
        }
    };

    repeater.addEventListener('update', onUpdate);
    repeater.addEventListener('updated', onUpdated);

    this.addEventListener('destroy', function(event) {
        repeater.removeEventListener('update', onUpdate);
        repeater.removeEventListener('updated', onUpdated);
    });
});
