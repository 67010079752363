dmx.Component('animate', {

    initialData: {
        visible: false,
        animating: false
    },

    tag: 'div',

    attributes: {
        showEffect: {
            type: String,
            default: ''
        },

        hideEffect: {
            type: String,
            default: ''
        },

        showDuration: {
            type: Number,
            default: null
        },

        hideDuration: {
            type: Number,
            default: null
        },

        showDelay: {
            type: Number,
            default: 0
        },

        hideDelay: {
            type: Number,
            default: 0
        },

        visible: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        animate: function(effect, duration) {
            if (this.props.visible) {
                dmx.animate(this.$node, effect, duration);
            }
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);

        if (!this.props.visible) {
            if (this.props.hideEffect) {
                dmx.animate(this.$node, this.props.hideEffect, 1);
            } else {
                this.$node.style.setProperty('visibility', 'hidden');
            }
        }
    },

    update: function(props) {
        if (this.props.visible != props.visible) {
            if (this.props.visible) {
                if (this.props.showEffect) {
                    dmx.animate(this.$node, this.props.showEffect, this.props.showDuration, this.props.showDelay);
                }
                this.$node.style.removeProperty('visibility');
            } else {
                if (this.props.hideEffect) {
                    dmx.animate(this.$node, this.props.hideEffect, this.props.hideDuration, this.props.hideDelay);
                } else {
                    this.$node.style.setProperty('visibility', 'hidden');
                }
            }

            this.set('visible', this.props.visible);
        }
    }

});
